const CATEGORIES = [
  { text: 'Authentications', checked: false, value: 'Authentications' },
  { text: 'Data At Risk', checked: false, value: 'Data At Risk' },
  { text: 'IAM Misconfigurations', checked: false, value: 'IAM Misconfigurations' },
  { text: 'Lateral Movement', checked: false, value: 'Lateral Movement' },
  { text: 'Malware', checked: false, value: 'Malware' },
  { text: 'Neglected Assets', checked: false, value: 'Neglected Assets' },
  { text: 'OS Vulnerabilities', checked: false, value: 'OS Vulnerabilities' },
  { text: 'Vulnerabilities', checked: false, value: 'Vulnerabilities' },
  { text: 'Other', checked: false, value: 'Other' },
];

// const CATEGORIES = [
//   { text: 'IAM', checked: false, value: 'IAM' },
//   { text: 'Logging', checked: false, value: 'Logging' },
//   { text: 'Monitoring', checked: false, value: 'Monitoring' },
//   { text: 'Networking', checked: false, value: 'Networking' },
//   { text: 'Security', checked: false, value: 'Security' },
//   { text: 'Reliability', checked: false, value: 'Reliability' },
//   { text: 'Cryptography', checked: false, value: 'Cryptography' },
//   { text: 'Audit - Log', checked: false, value: 'Audit - Log' },
//   { text: 'Storage', checked: false, value: 'Storage' },
//   { text: 'SQL Database', checked: false, value: 'SQL Database' },
//   { text: 'SQL Server', checked: false, value: 'SQL Server' },
//   { text: 'Performance, Cost', checked: false, value: 'Performance, Cost' },
//   { text: 'Encryption Keys', checked: false, value: 'Encryption Keys' },
// ];

const FUNCTIONS = [
  {
    text: 'Cryptography',
    checked: false,
    value: 'Cryptography',
  },
  {
    text: 'Audit - Log',
    checked: false,
    value: 'Audit - Log',
  },
  {
    text: 'Key Vault',
    checked: false,
    value: 'Key Vault',
  },
  {
    text: 'Integrity',
    checked: false,
    value: 'Integrity',
  },
  {
    text: 'Access Control',
    checked: false,
    value: 'Access Control',
  },
  {
    text: 'Communication',
    checked: false,
    value: 'Communication',
  },
  {
    text: 'Operations',
    checked: false,
    value: 'Operations',
  },
  {
    text: 'Data Protection',
    checked: false,
    value: 'Data Protection',
  },
  {
    text: 'Logging and Monitoring',
    checked: false,
    value: 'Logging and Monitoring',
  },
  {
    text: 'Database Protection',
    checked: false,
    value: 'Database Protection',
  },
  {
    text: 'SQL Server Protection',
    checked: false,
    value: 'SQL Server Protection',
  },
  {
    text: 'Business Continuity Mngt (BCM)',
    checked: false,
    value: 'Business Continuity Mngt (BCM)',
  },
  {
    text: 'App Service',
    checked: false,
    value: 'App Service',
  },
];

let PROVIDER_SERVICES_META = {
  AWS: {
    S3: {
      value: 'AWS Simple Storage Service (S3)',
      shortName: 'S3',
      category: 'Storage',
      serviceName: 'S3',
    },
    DDB: {
      value: 'AWS DynamoDB (DDB)',
      shortName: 'DDB',
      category: 'Database',
      serviceName: 'DynamoDB',
    },
    CLT: {
      value: 'AWS CloudTrail (CLT)',
      shortName: 'CLT',
      category: 'Management & Governance',
      serviceName: 'CloudTrail',
    },
    CF: {
      value: 'AWS CloudFront (CF)',
      shortName: 'CF',
      category: 'Networking',
      serviceName: 'CloudFront',
    },
    ELB: {
      value: 'AWS Elastic Load Balancing (ELB)',
      shortName: 'ELB',
      category: 'Networking',
      serviceName: 'Elastic Load Balancing',
    },
    IAM: {
      value: 'AWS Identity Access Management (IAM)',
      shortName: 'IAM',
      category: 'Security Identity & Compliance',
      serviceName: 'IAM',
    },
    RDS: {
      value: 'AWS Relational Database Service (RDS)',
      shortName: 'RDS',
      category: 'Database',
      serviceName: 'RDS',
    },
    LAMBDA: {
      value: 'AWS Lambda',
      shortName: 'Lambda',
      category: 'Compute',
      serviceName: 'Lambda',
    },
    VPC: {
      value: 'AWS Virtual Private Cloud (VPC)',
      shortName: 'VPC',
      category: 'Networking',
      serviceName: 'VPC',
    },
    KMS: {
      value: 'AWS Key Management Service (KMS)',
      shortName: 'KMS',
      category: 'Security Identity & Compliance',
      serviceName: 'KMS',
    },
    CWALM: {
      value: 'AWS Cloud Watch Alarm (CWALM)',
      shortName: 'CWALM',
      category: 'Management & Governance',
      serviceName: 'CloudWatch',
    },
    CWEVENTS: {
      value: 'AWS Cloud Watch Events (CWEVENTS)',
      shortName: 'CWEVENTS',
      category: 'Management & Governance',
      serviceName: 'CloudWatch',
    },

    ECS: {
      value: 'AWS Elastic Container Service (ECS)',
      shortName: 'ECS',
      category: 'Container',
      serviceName: 'ECS',
    },

    ECR: {
      value: 'AWS Elastic Container Registry (ECR)',
      shortName: 'ECR',
      category: 'Container',
      serviceName: 'ECR',
    },

    EKS: {
      value: 'AWS Elastic Kubernetes Service (EKS)',
      shortName: 'EKS',
      category: 'Container',
      serviceName: 'EKS',
    },
    AG: {
      value: 'AWS API Gateway (AG)',
      shortName: 'AG',
      category: 'Networking',
      serviceName: 'API Gateway',
    },
    GLUE: {
      value: 'AWS Glue',
      shortName: 'Glue',
      category: 'Analytics',
      serviceName: 'Glue',
    },
    'EC2:ElasticIP': {
      value: 'AWS EC2:ElasticIP',
      shortName: 'Elastic IP',
      category: 'Networking',
      serviceName: 'EC2',
    },
    'EC2:Instance': {
      value: 'AWS EC2:Instance',
      shortName: 'Instance',
      category: 'Compute',
      serviceName: 'EC2',
    },
    'EC2:RI': {
      value: 'AWS EC2:Reserved Instance',
      shortName: 'RI',
      category: 'Compute',
      serviceName: 'EC2',
    },
    'EC2:SecurityGroups': {
      value: 'AWS EC2:SecurityGroups',
      shortName: 'Security Groups',
      category: 'Networking',
      serviceName: 'EC2',
    },
    'EC2:Volume': {
      value: 'AWS EC2 Volume',
      shortName: 'Volume',
      category: 'Storage',
      serviceName: 'EC2',
    },
    'EC2:SnapShot': {
      value: 'AWS EC2 Snapshot',
      shortName: 'Snapshot',
      category: 'Compute',
      serviceName: 'EC2',
    },
    Elasticsearch: {
      value: 'AWS Elastic search',
      shortName: 'AWS Elastic',
      category: 'Analytics',
      serviceName: 'Amazon OpenSearch Service',
    },
    'EC2:AMI': {
      value: 'AWS EC2: Machine Image (AMI)',
      shortName: 'AMI',
      category: 'Compute',
      serviceName: 'EC2',
    },
    CONFIG: {
      value: 'AWS Config',
      shortName: 'CONFIG',
      category: 'Management & Governance',
      serviceName: 'CONFIG',
    },
  },
  AZURE: {
    AD: {
      value: 'Microsoft Entra ID',
      shortName: 'AD',
      category: 'Security Identity & Compliance',
      serviceName: 'Azure EntraID',
    },
    ALA: {
      value: 'AZURE Monitor (Alerts)',
      shortName: 'ALA',
      category: 'Management & Governance',
      serviceName: 'Monitor',
    },
    KV: {
      shortName: 'KV',
      value: 'AZURE Key Vault (KV)',
      category: 'Security Identity & Compliance',
      serviceName: 'Key Vault',
    },
    NSG: {
      value: 'AZURE Network Security Group (NSG)',
      shortName: 'NSG',
      category: 'Networking',
      serviceName: 'Network Security Group',
    },
    SA: {
      value: 'AZURE Storage Account (SA)',
      shortName: 'SA',
      category: 'Storage',
      serviceName: 'Storage Account',
    },
    logProfiles: {
      value: 'AZURE Log Profiles (logProfiles)',
      shortName: 'logProfiles',
      category: 'Security Identity & Compliance',
      serviceName: 'Log Profiles',
    },
    PLNK: {
      value: 'AZURE Private Link (PLNK)',
      shortName: 'PLNK',
      category: 'Networking',
      serviceName: 'Private Link',
    },
    SQLSVR: {
      value: 'AZURE SQL Server (SQLSVR)',
      shortName: 'SQLSVR',
      category: 'Database',
      serviceName: 'SQL Server',
    },
    SQLDB: {
      value: 'AZURE SQL Database (SQLDB)',
      shortName: 'SQLDB',
      category: 'Database',
      serviceName: 'SQL Database',
    },
    VM: {
      value: 'AZURE Virtual Machine (VM)',
      shortName: 'VM',
      category: 'Compute',
      serviceName: 'Virtual Machine',
    },
    WEBAPPS: {
      value: 'AZURE Web Apps (WEBAPPS)',
      shortName: 'WEBAPPS',
      category: 'Compute',
      serviceName: 'Web Apps',
    },
    SBNET: {
      value: 'AZURE Subnet',
      shortName: 'SBNET',
      category: 'Networking',
      serviceName: 'Subnet',
    },
    DISKS: {
      value: 'AZURE Disks',
      shortName: 'DISKS',
      category: 'Compute',
      serviceName: 'Disks',
    },
    DATABRICKS: {
      value: 'AZURE Databricks',
      shortName: 'DATABRICKS',
      category: 'Database',
      serviceName: 'Databricks',
    },
    COSMOSDB: {
      value: 'AZURE Cosmos DB',
      shortName: 'COSMOSDB',
      category: 'Database',
      serviceName: 'Cosmos DB',
    },
    AKS: {
      value: 'AZURE Kubernetes Service (AKS)',
      shortName: 'AKS',
      category: 'Container',
      serviceName: 'Kubernetes Service',
    },
    NAMESPACES: {
      value: 'AZURE Service Bus (ASB)',
      shortName: 'ASB',
      category: 'Compute',
      serviceName: 'Service Bus',
    },
    LOADBALANCERS: {
      value: 'AZURE Load Balancer (ALB)',
      shortName: 'ALB',
      category: 'Networking',
      serviceName: 'Load Balancer',
    },
    PUBLICIPADDRESSES: {
      value: 'AZURE Public IP Addresses (PIP)',
      fullName: 'AZURE Public IP Addresses (Public IP)',
      shortName: 'Public IP',
      apiService: '',
      category: 'Networking',
      iconName: 'hdd',
      getProperties: true,
      endpoint: 'get',
    },

    ACTIONGROUPS: {
      value: 'AZURE Action Groups (AG)',
      fullName: 'AZURE Action Groups (AG)',
      shortName: 'AG',
      apiService: '',
      category: 'Others',
      iconName: 'desktop',
      getProperties: true,
      endpoint: 'get',
    },
    RESTOREPOINTCOLLECTIONS: {
      value: 'AZURE Restore Point Collections (RPC)',
      fullName: 'AZURE Restore Point Collections (RPC)',
      shortName: 'RPC',
      apiService: '',
      category: 'Compute',
      iconName: 'desktop',
      getProperties: true,
      endpoint: 'get',
    },
    SSHPUBLICKEYS: {
      value: 'AZURE SSH Public Keys (SSHPK)',
      fullName: 'AZURE SSH Public Keys (SSHPK)',
      shortName: 'SSHPK',
      apiService: '',
      category: 'Networking',
      iconName: 'hdd',
      getProperties: true,
      endpoint: 'get',
    },
    EXTENSIONS: {
      value: 'AZURE Extensions (EXT)',
      fullName: 'AZURE Extensions (EXT)',
      shortName: 'EXT',
      apiService: '',
      category: 'Networking',
      iconName: 'desktop',
      getProperties: true,
      endpoint: 'get',
    },
    NETWORKINTERFACES: {
      value: 'AZURE Network Interfaces (NI)',
      fullName: 'AZURE Network Interfaces (NI)',
      shortName: 'NI',
      apiService: '',
      category: 'Networking',
      iconName: 'hdd',
      getProperties: true,
      endpoint: 'get',
    },
    ROUTETABLES: {
      value: 'AZURE Route Tables (RT)',
      fullName: 'AZURE Route Tables (RT)',
      shortName: 'RT',
      apiService: '',
      category: 'Networking',
      iconName: 'hdd',
      getProperties: true,
      endpoint: 'get',
    },
    USERASSIGNEDIDENTITIES: {
      value: 'AZURE User Assigned Identities (UAI)',
      fullName: 'AZURE User Assigned Identities (UAI)',
      shortName: 'UAI',
      apiService: '',
      category: 'Security Identity & Compliance',
      iconName: 'team',
      getProperties: true,
      endpoint: 'get',
    },
    OPERATIONALINSIGHTS: {
      value: 'AZURE Operational Insights (OI)',
      fullName: 'AZURE Operational Insights (OI)',
      shortName: 'OI',
      apiService: '',
      category: 'Others',
      iconName: 'desktop',
      getProperties: true,
      endpoint: 'get',
    },
    SOLUTIONS: {
      value: 'AZURE Solutions (SOL)',
      fullName: 'AZURE Solutions (SOL)',
      shortName: 'SOL',
      apiService: '',
      category: 'Others',
      iconName: 'desktop',
      getProperties: true,
      endpoint: 'get',
    },
    SERVERFARMS: {
      value: 'AZURE Server Farms (SF)',
      fullName: 'AZURE Server Farms (SF)',
      shortName: 'SF',
      apiService: '',
      category: 'Compute',
      iconName: 'desktop',
      getProperties: true,
      endpoint: 'get',
    },
    BATCHACCOUNTS: {
      value: 'AZURE Batch Accounts (BA)',
      fullName: 'AZURE Batch Accounts (BA)',
      shortName: 'BA',
      apiService: '',
      category: 'Compute',
      iconName: 'desktop',
      getProperties: true,
      endpoint: 'get',
    },

    CONNECTIONS: {
      value: 'AZURE Connections (CONN)',
      fullName: 'AZURE Connections (CONN)',
      shortName: 'CONN',
      apiService: '',
      category: 'Networking',
      iconName: 'hdd',
      getProperties: true,
      endpoint: 'get',
    },
    PRIVATEDNSZONES: {
      value: 'AZURE Private DNS Zones (PDZ)',
      fullName: 'AZURE Private DNS Zones (PDZ)',
      shortName: 'PDZ',
      apiService: '',
      category: 'Networking',
      iconName: 'hdd',
      getProperties: true,
      endpoint: 'get',
    },
    VIRTUALNETWORKLINKS: {
      value: 'AZURE Virtual Network Links (VNL)',
      fullName: 'AZURE Virtual Network Links (VNL)',
      shortName: 'VNL',
      apiService: '',
      category: 'Networking',
      iconName: 'hdd',
      getProperties: true,
      endpoint: 'get',
    },
    NETWORKWATCHERS: {
      value: 'AZURE Network Watchers (NW)',
      fullName: 'AZURE Network Watchers (NW)',
      shortName: 'NW',
      apiService: '',
      category: 'Networking',
      iconName: 'hdd',
      getProperties: true,
      endpoint: 'get',
    },
    FLOWLOGS: {
      value: 'AZURE Flow Logs (FL)',
      fullName: 'AZURE Flow Logs (FL)',
      shortName: 'FL',
      apiService: '',
      category: 'Security Identity & Compliance',
      iconName: 'desktop',
      getProperties: true,
      endpoint: 'get',
    },
    SNAPSHOTS: {
      value: 'AZURE Snapshots (SN)',
      fullName: 'AZURE Snapshots (SN)',
      shortName: 'SN',
      apiService: '',
      category: 'Compute',
      iconName: 'desktop',
      getProperties: true,
      endpoint: 'get',
    },
    SYSTEMTOPICS: {
      value: 'AZURE System Topics (ST)',
      fullName: 'AZURE System Topics (ST)',
      shortName: 'ST',
      apiService: '',
      category: 'Others',
      iconName: 'desktop',
      getProperties: true,
      endpoint: 'get',
    },
    COMPONENTS: {
      value: 'AZURE Components (COMP)',
      fullName: 'AZURE Components (COMP)',
      shortName: 'COMP',
      apiService: '',
      category: 'Others',
      iconName: 'desktop',
      getProperties: true,
      endpoint: 'get',
    },
    SMARTDETECTORALERTRULES: {
      value: 'AZURE Smart Detector Alert Rules (SDAR)',
      fullName: 'AZURE Smart Detector Alert Rules (SDAR)',
      shortName: 'SDAR',
      apiService: '',
      category: 'Others',
      iconName: 'desktop',
      getProperties: true,
      endpoint: 'get',
    },
  },
  GCP: {
    datastore: {
      value: 'GCP Datastore',
      shortName: 'Datastore',
      category: 'Database',
      serviceName: 'Datastore',
    },
    ServiceAccount: {
      value: 'GCP Service Account',
      shortName: 'Service Account',
      category: 'Security Identity & Compliance',
      serviceName: 'Service Account',
    },
    Instance: {
      value: 'GCP Instance',
      shortName: 'Instance',
      category: 'Compute',
      serviceName: 'Instance',
    },
    CloudFunction: {
      value: 'GCP Cloud Function',
      shortName: 'CF',
      category: 'Compute',
      serviceName: 'Cloud Function',
    },
    storage: {
      value: 'GCP Storage',
      shortName: 'Storage',
      category: 'Storage',
      serviceName: 'Storage',
    },
    Firestore: {
      value: 'GCP Firestore',
      shortName: 'Firestore',
      category: 'Database',
      serviceName: 'Firestore',
    },
    cloudkms: {
      value: 'GCP Key Management Service (KMS)',
      shortName: 'KMS',
      category: 'Security Identity & Compliance',
      serviceName: 'Key Management Service',
    },
    Network: {
      value: 'GCP Network',
      shortName: 'Network',
      category: 'Networking',
      serviceName: 'Network',
    },
    SQL: {
      value: 'GCP Cloud SQL',
      shortName: 'SQL',
      category: 'Database',
      serviceName: 'Cloud SQL',
    },
    LogBucket: {
      value: 'GCP Audit-Log',
      shortName: 'Audit-Log',
      category: 'Management & Governance',
      serviceName: 'Audit-Log',
    },
    GKE: {
      value: 'Google Kubernetes Engine',
      shortName: 'GKE',
      category: 'Container',
      serviceName: 'Kubernetes Engine',
    },
    GCR: {
      value: 'Google Container Registry',
      shortName: 'GCR',
      category: 'Container',
      serviceName: 'Container Registry',
    },
  },
  CA: {
    cloudAccount: {
      value: 'Cloud Account',
      shortName: 'Cloud Account',
      category: 'Compute',
      serviceName: 'Cloud Account',
    },
  },
};

const SERVICES_LIST = [];
for (const providerKey in PROVIDER_SERVICES_META) {
  const eachProviderServices = PROVIDER_SERVICES_META[providerKey];
  for (const serviceKey in eachProviderServices) {
    let eachService = eachProviderServices[serviceKey];

    eachService.key = serviceKey;
    eachService.provider = providerKey;

    // if (serviceKey === 'cloudAccount' && providerKey !== 'CA') {
    // } else {
    SERVICES_LIST.push(eachService);
    // }
  }
}
let CA = {
  value: 'Cloud Account',
  shortName: 'Cloud Account',
  key: 'cloudAccount',
  provider: 'CA',
};

let SERVICE_CATEGORIES = [
  ...new Set(SERVICES_LIST.map(service => service.category).filter(service => service)),
];

// all 23 regions from AWS
const REGIONS = [
  'us-east-1',
  'us-east-2',
  'us-west-1',
  'us-west-2',
  'ap-south-1',
  'ap-northeast-3',
  'ap-northeast-2',
  'ap-southeast-1',
  'ap-southeast-2',
  'ap-northeast-1',
  'ca-central-1',
  'eu-central-1',
  'eu-west-1',
  'eu-west-2',
  'eu-west-3',
  'eu-north-1',
  'sa-east-1',
  'af-south-1',
  'ap-east-1',
  'ap-south-2',
  'ap-southeast-3',
  'eu-south-1',
  'eu-south-2',
  'eu-central-2',
  'me-south-1',
  'me-central-1',
  '',
];
const SEVERITY_LEVELS_WITH_MAGNITUDES = {
  //level 1 means most important/severe
  CRITICAL: { value: 'critical', level: 4 },
  HIGH: { value: 'high', level: 3 },
  MEDIUM: { value: 'medium', level: 2 },
  LOW: { value: 'low', level: 1 },
};
let GLOBAL_META_CONFIG = {
  REGIONS,
  CLOUD_ACCOUNT_PROVIDERS: ['AWS', 'GCP', 'AZURE'],
  SECURITY_GROUPS: [
    'Disaster Recovery',
    'Encryption in Motion',
    'Encryption at Rest',
    'Encryption in Transit',
    'Logging',
    'Monitoring',
    'Security',
    'IAM',
    'Networking',
    'Performance',
  ],
  SEVERITY_LEVELS: ['Low', 'Medium', 'High', 'Critical'],
  SEVERITY_LEVELS_WITH_MAGNITUDES: SEVERITY_LEVELS_WITH_MAGNITUDES,
  All: SERVICES_LIST,
  AWS: SERVICES_LIST.filter(service => service.provider === 'AWS').concat(CA),
  AZURE: SERVICES_LIST.filter(service => service.provider === 'AZURE').concat(CA),
  GCP: SERVICES_LIST.filter(service => service.provider === 'GCP').concat(CA),
  PROVIDER_SERVICES_META,
  CATEGORIES,
  FUNCTIONS,
  SERVICE_CATEGORIES,
  getFullName: key => {
    let a = SERVICES_LIST.find(service => service.key === key)?.value || `${key}`; // N/A
    return a;
  },
  getShortName: key => {
    let a = SERVICES_LIST.find(service => service.key === key)?.shortName || `${key}`; // N/A
    return a;
  },
  getServiceName: key => {
    let a = SERVICES_LIST.find(service => service.key === key)?.serviceName || `${key}`; // N/A
    return a;
  },
};

export default GLOBAL_META_CONFIG;
