import { startThread, sendMessage } from '@/services/cloudIQ';
import { message, notification } from 'antd';

const namespace = 'cloudIQ';

export default {
  namespace,

  state: {
    threadId: null,
    messages: [],
    loading: false,
  },

  effects: {
    *startThread(_, { call, put }) {
      yield put({
        type: 'setLoading',
        payload: true,
      });
      try {
        const response = yield call(startThread);
        yield put({
          type: 'saveThreadId',
          payload: response.threadId,
        });
      } catch (error) {
        notification.error({
          message: 'Failed to start thread',
          description: error.message,
        });
      } finally {
        yield put({
          type: 'setLoading',
          payload: false,
        });
      }
    },

    *sendMessage({ payload }, { call, put, select }) {
      yield put({
        type: 'setLoading',
        payload: true,
      });
      const { selectedCloudAccountsIds, orgId } = payload;
      const threadId = yield select(state => state[namespace].threadId);

      try {
        const response = yield call(sendMessage, {
          message: payload.message,
          threadId,
          organizationId: orgId,
          cloudAccountIds: selectedCloudAccountsIds,
        });

        sessionStorage.setItem(
          'cloudIQthread',
          JSON.stringify({
            threadId,
            messages: response.messages,
          })
        );

        yield put({
          type: 'updateChatHistory',
          payload: response.messages.reverse(),
        });
      } catch (error) {
        notification.error({
          message: 'Failed to send message',
          description: error.message,
        });
      } finally {
        yield put({
          type: 'setLoading',
          payload: false,
        });
      }
    },

    *newChat(_, { put, select }) {
      const threadId = yield select(state => state[namespace].threadId);

      if (threadId) {
        sessionStorage.removeItem('cloudIQthread');
      }

      yield put({
        type: 'saveThreadId',
        payload: null,
      });
      yield put({
        type: 'updateChatHistory',
        payload: [],
      });
      yield put({
        type: 'startThread',
      });
    },

    *saveThreadFromLocalStorage({ payload }, { put }) {
      const { threadId, messages } = payload;

      yield put({
        type: 'saveThreadId',
        payload: threadId,
      });
      yield put({
        type: 'updateChatHistory',
        payload: messages.reverse(),
      });
    },
  },

  reducers: {
    setLoading(state, { payload }) {
      return {
        ...state,
        loading: payload,
      };
    },

    saveThreadId(state, { payload }) {
      return {
        ...state,
        threadId: payload,
      };
    },

    updateChatHistory(state, { payload }) {
      return {
        ...state,
        messages: payload,
      };
    },
  },
};
