import axios from 'axios';
import { stringify } from 'qs';

const functionsApi = 'https://us-central1-temp-cloudnosys.cloudfunctions.net/CloudIQ';

export async function startThread() {
  try {
    const response = await axios.get(`${functionsApi}/start-thread`);

    if (!response || !response.data || !response.data.threadId) {
      throw new Error('Invalid API response');
    }

    return response.data;
  } catch (error) {
    console.error('Error in startThread:', error);
    throw error;
  }
}

export async function sendMessage(params = {}) {
  try {
    const response = await axios.post(`${functionsApi}/message`, params);

    if (!response || !response.data) {
      throw new Error('Invalid API response');
    }

    return response.data;
  } catch (error) {
    console.error('Error in sendMessage:', error);
    throw error;
  }
}
