import dva from 'dva';
import { Component } from 'react';
import createLoading from 'dva-loading';
import history from '@tmp/history';

let app = null;

export function _onCreate() {
  const plugins = require('umi/_runtimePlugin');
  const runtimeDva = plugins.mergeConfig('dva');
  app = dva({
    history,
    
    ...(runtimeDva.config || {}),
    ...(window.g_useSSR ? { initialState: window.g_initialData } : {}),
  });
  
  app.use(createLoading());
  (runtimeDva.plugins || []).forEach(plugin => {
    app.use(plugin);
  });
  
  app.model({ namespace: '_risks', ...(require('/home/amas/Desktop/frontend-v3/src/models/_risks.js').default) });
app.model({ namespace: 'accessControl', ...(require('/home/amas/Desktop/frontend-v3/src/models/accessControl.js').default) });
app.model({ namespace: 'billingDashboard', ...(require('/home/amas/Desktop/frontend-v3/src/models/billingDashboard.js').default) });
app.model({ namespace: 'cloudAccount', ...(require('/home/amas/Desktop/frontend-v3/src/models/cloudAccount.js').default) });
app.model({ namespace: 'cloudAccounts', ...(require('/home/amas/Desktop/frontend-v3/src/models/cloudAccounts.js').default) });
app.model({ namespace: 'cloudIQ', ...(require('/home/amas/Desktop/frontend-v3/src/models/cloudIQ.js').default) });
app.model({ namespace: 'cloudviz', ...(require('/home/amas/Desktop/frontend-v3/src/models/cloudviz.js').default) });
app.model({ namespace: 'customRegulations', ...(require('/home/amas/Desktop/frontend-v3/src/models/customRegulations.js').default) });
app.model({ namespace: 'customSignatures', ...(require('/home/amas/Desktop/frontend-v3/src/models/customSignatures.js').default) });
app.model({ namespace: 'editor', ...(require('/home/amas/Desktop/frontend-v3/src/models/editor.js').default) });
app.model({ namespace: 'global', ...(require('/home/amas/Desktop/frontend-v3/src/models/global.js').default) });
app.model({ namespace: 'integrations', ...(require('/home/amas/Desktop/frontend-v3/src/models/integrations.js').default) });
app.model({ namespace: 'login', ...(require('/home/amas/Desktop/frontend-v3/src/models/login.js').default) });
app.model({ namespace: 'menu', ...(require('/home/amas/Desktop/frontend-v3/src/models/menu.js').default) });
app.model({ namespace: 'notifications', ...(require('/home/amas/Desktop/frontend-v3/src/models/notifications.js').default) });
app.model({ namespace: 'organisation', ...(require('/home/amas/Desktop/frontend-v3/src/models/organisation.js').default) });
app.model({ namespace: 'regulations', ...(require('/home/amas/Desktop/frontend-v3/src/models/regulations.js').default) });
app.model({ namespace: 'reports', ...(require('/home/amas/Desktop/frontend-v3/src/models/reports.js').default) });
app.model({ namespace: 'resources', ...(require('/home/amas/Desktop/frontend-v3/src/models/resources.js').default) });
app.model({ namespace: 'resourcesReport', ...(require('/home/amas/Desktop/frontend-v3/src/models/resourcesReport.js').default) });
app.model({ namespace: 'Risks', ...(require('/home/amas/Desktop/frontend-v3/src/models/Risks.js').default) });
app.model({ namespace: 'scan', ...(require('/home/amas/Desktop/frontend-v3/src/models/scan.js').default) });
app.model({ namespace: 'scopeReport', ...(require('/home/amas/Desktop/frontend-v3/src/models/scopeReport.js').default) });
app.model({ namespace: 'setting', ...(require('/home/amas/Desktop/frontend-v3/src/models/setting.js').default) });
app.model({ namespace: 'SignatureMetaData', ...(require('/home/amas/Desktop/frontend-v3/src/models/SignatureMetaData.js').default) });
app.model({ namespace: 'singleRisk', ...(require('/home/amas/Desktop/frontend-v3/src/models/singleRisk.js').default) });
app.model({ namespace: 'suppression', ...(require('/home/amas/Desktop/frontend-v3/src/models/suppression.js').default) });
app.model({ namespace: 'universalDrawer', ...(require('/home/amas/Desktop/frontend-v3/src/models/universalDrawer.js').default) });
app.model({ namespace: 'user', ...(require('/home/amas/Desktop/frontend-v3/src/models/user.js').default) });
app.model({ namespace: 'widget', ...(require('/home/amas/Desktop/frontend-v3/src/models/widget.js').default) });
  return app;
}

export function getApp() {
  return app;
}

export class _DvaContainer extends Component {
  render() {
    const app = getApp();
    app.router(() => this.props.children);
    return app.start()();
  }
}
