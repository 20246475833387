import slugify from 'slugify';
import { eventChannel } from 'dva/saga';
import { message, notification } from 'antd';
import { firebase, firebaseApp, firestore, functions } from '@/utils/firebase';
import errorHandler from '@/utils/errorHandler';
import { getAuthority } from '@/utils/authority';
import { list } from '@/services/firestore';
import { getDefaultPackage } from './organisation';

export const checkMarketPlaceStatus = async ({ id, orgId }) => {
  const res = await functions.httpsCallable(
    'Integration-awsMarketPlaceStatus-checkmarketPlaceStatus'
  )({
    id,
    orgId,
  });
  return res;
};

export const getSamlIds = async email => {
  const res = await functions.httpsCallable('Integration-getSamlIdsByEmail')({
    email: email,
    orgId: localStorage.getItem('orgId'),
  });
  return res;
};

export const verifyEmail = async uid => {
  const res = await functions.httpsCallable('accessControl-verifyEmail')({
    uid: uid,
    orgId: localStorage.getItem('orgId'),
  });
  return res;
};

export const fetchLogin = (email, password) => {
  return firebaseApp.auth().signInWithEmailAndPassword(email, password);
};
export const removeUserOrg = async payload => {
  let delOrg = payload.orgId;
  const user = await currentUserDetail();
  let userData = await user.data();
  let orgArr = userData.organisations;
  let updatedOrg = orgArr.filter(o => o !== delOrg);
  if (updatedOrg) {
    await firestore
      .collection('Users')
      .doc(payload.userId)
      .update({
        organisations: updatedOrg,
      });
    notification.success({
      message: 'Organization Deleted',
    });
  }
};

export const authenticateWithSaml = async payload => {
  try {
    const { providerId } = payload.payload;
    const samlAuthProvider = await new firebase.auth.SAMLAuthProvider(providerId);
    return firebase.auth().signInWithRedirect(samlAuthProvider);
  } catch (err) {
    console.error('error in authenticating with saml ', err);
  }
};

export const fetchLogout = () => {
  return firebaseApp.auth().signOut();
};

export const currentUser = () => {
  return firebaseApp.auth().currentUser;
};

export const uploadReport = async report => {
  try {
    await firestore
      .collection('Organizations')
      .doc('metrics_adi5z')
      .collection('cloudAccounts')
      .doc('ChsQ5iQhgpd92VV8BsmP')
      .collection('latestReport')
      .doc(report.regulationId)
      .set(report);
  } catch (error) {
    console.log(error);
  }
};

export const sendEmailVerification = async () => {
  const user = await currentUserDetail();

  let userData = await user.data();

  const { isVerified } = userData;

  if (!isVerified) {
    await firestore
      .collection('Users')
      .doc(userData.uid)
      .update({
        sendVerification: true,
      });
    message.success('Verification link has been sent to your email address.');
  }
};

export const userRegister = async payload => {
  const { Email, Organization, User, phonenum, password, host, productCode } = payload;

  try {
    const response = await firebaseApp.auth().createUserWithEmailAndPassword(Email, password);

    //setup profile
    await firestore
      .collection('Users')
      .doc(response.user.uid)
      .set({
        name: User,
        email: response.user.email,
        address: '',
        phone: phonenum,
        isVerified: false,
        organisations: [],
        host: host,
        riskFilterPresets: [],
        inventoryFilterPresets: [],
        updatedAt: new Date(),
        createdAt: new Date(),
        Role: 'PARENT_USER',
        company: Organization,
        uid: response.user.uid,
      });

    await createOrganisation({ name: Organization, fromReg: true, productCode });

    // send email verification if user email is not verified
    const shouldSentVerification = !response.user.emailVerified;

    if (shouldSentVerification) {
      message.info('Verification email sent to your email address');
    }

    return true;
  } catch (error) {
    message.error(error.message);
    errorHandler({
      status: error.code,
      statusText: error.message,
      statusRaw: error,
    });
    return false;
  }
};

export const sendResetPasswordEmail = async email => {
  return firebase.auth().sendPasswordResetEmail(email);
};
export const getpasswordb = async userId => {
  try {
    let data = await firestore
      .collection('Users')
      .doc(userId)
      .get();
    let db = data.data();

    let dbIschild;
    if (db && db.Role === 'CHILD_USER' && db.ChangeAutoGeneratePassword === false) {
      // save password because in case of childuser User can update password by using this
      dbIschild = true;
    } else {
      dbIschild = false;
    }
    return dbIschild;
  } catch (err) {
    console.error('error in getting password ', err);
  }
};

// used for logging in current user
export const authChange = callback => {
  firebaseApp.auth().onAuthStateChanged(callback);
};

export const currentUserDetail = async () => {
  const user = currentUser();
  if (!user) return false;
  firebaseApp
    .auth()
    .currentUser.getIdToken(/* forceRefresh */ true)
    .then(() => {
      // Send token to your backend via HTTPS
      // ...
    })
    .catch(error => {
      // Handle error
      console.error('error in getting token ', error);
    });
  let ref = firestore
    .collection('Users')
    .doc(user.uid)
    .get();
  return ref;
};

export async function currentUserInfo() {
  const user = currentUser();

  if (!user) return false;
  let ref = firestore.collection('Users').doc(user.uid);

  return eventChannel(emitter => {
    const unsubscribe = ref.onSnapshot(async querySnapshot => {
      let singleDoc = {};
      if (querySnapshot.exists) {
        const data = querySnapshot.data();
        singleDoc = {
          avatar: 'https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png',
          signature: 'signature',
          title: 'title',
          group: 'group',
          tags: [],
          country: '',
          geographic: {
            province: {
              label: 'Sindh',
              key: '330000',
            },
            city: {
              label: 'Karachi',
              key: '75300',
            },
          },

          id: querySnapshot.id,
          email: data.email ? data.email : user.email,
          ...data,
        };
      }

      emitter(singleDoc);
      singleDoc = {};
    });
    // The subscriber must return an unsubscribe function
    return () => unsubscribe();
  });
}

export const doVerify = uid => {
  return firestore
    .collection('Users')
    .doc(uid)
    .update({
      isVerified: true,
    });
};
// check provided email in firestore
export const checkEmailValidation = async email => {
  let response = await firestore
    .collection('Users')
    .where('email', '==', email)
    .limit(1)
    .get();
  return response.docs;
};

export const updateAlertCount = async payload => {
  try {
    const user = currentUser();
    if (!user) return false;
    const getCurrentUser = await firestore
      .collection('Users')
      .doc(user.uid)
      .get();
    let currentAlertCount = getCurrentUser.data().alertsCount;

    if (payload && payload.type === 'risk') {
      await firestore
        .collection('Users')
        .doc(user.uid)
        .set(
          {
            alertsCount: {
              [payload.organisation]: {
                risks: 0,
                systems: currentAlertCount[payload.organisation].systems,
              },
            },
          },
          {
            merge: true,
          }
        );
      return;
    } else {
      await firestore
        .collection('Users')
        .doc(user.uid)
        .set(
          {
            alertsCount: {
              [payload.organisation]: {
                risks: currentAlertCount[payload.organisation].risks,
                systems: 0,
              },
            },
          },
          {
            merge: true,
          }
        );
      return;
    }
  } catch (error) {
    errorHandler({
      status: error.code,
      statusText: error.message,
      statusRaw: error,
    });
    return false;
  }
};

// update user profile
export const updateUserProfile = async payload => {
  const user = await currentUser();
  if (!user) return false;
  // if type is email then it will only update user email
  if (payload.type === 'email') {
    if (payload.email) {
      await user.updateEmail(payload.email);
      await firestore
        .collection('Users')
        .doc(user.uid)
        .update({
          email: payload.email,
        });
    }
    return true;
  } else {
    //else it will update all provided fields
    try {
      await firestore
        .collection('Users')
        .doc(user.uid)
        .update({
          ...payload,
        });
      return true;
    } catch (error) {
      errorHandler({
        status: error.code,
        statusText: error.message,
        statusRaw: error,
      });
      return false;
    }
  }
};

export async function getUser(id) {
  try {
    let querySnapshot = await firestore
      .collection('Users')
      .doc(id)
      .get();
    let singleDoc = {};
    if (querySnapshot.exists) {
      singleDoc = {
        id: querySnapshot.id,
        ...querySnapshot.data(),
      };
    }
    return singleDoc;
  } catch (error) {
    errorHandler({
      status: error.code,
      statusText: error.message,
      statusRaw: error,
    });
    return false;
  }
}

export async function fetchUserAlerts() {
  const user = currentUser();
  if (!user) {
    return false;
  }

  const ref = firestore
    .collection('Users')
    .doc(user.uid)
    .collection('alerts')
    .orderBy('time', 'desc')
    .limit(20);

  return eventChannel(emitter => {
    const unsubscribe = ref.onSnapshot(
      querySnapshot => {
        let docs = [];
        querySnapshot.forEach(doc => {
          if (doc.exists) {
            docs.push({
              id: doc.id,
              ref: doc.ref,
              ...doc.data(),
              time: doc.data().time.toDate(),
            });
          }
        });

        emitter(docs);
        docs = [];
      },
      err => {
        errorHandler({
          status: err.code,
          statusText: err.message,
          statusRaw: err,
        });
        emitter([]);
      }
    );
    // The subscriber must return an unsubscribe function
    return () => unsubscribe();
  });
}

function getRandomString(length) {
  let s = '';
  do {
    s += Math.random()
      .toString(36)
      .substr(2);
  } while (s.length < length);
  s = s.substr(0, length);

  return s;
}

export const createOrganisation = async payload => {
  try {
    const id = `${slugify(payload.name)}_${getRandomString(5)}`.toLowerCase();
    if (payload.fromReg) {
      localStorage.setItem('orgId', id);
    }
    const user = await currentUser();
    if (!user) throw new Error('no logged in user');

    let orgData = {
      time: new Date(),
      owner: user.uid,
      name: payload.name,
      sentEmailOnExpiration: false,
      package: await getDefaultPackage(),
      xrayEnabled: true,
      cloudViewEnabled: false,
      cloudIQEnabled: false,
      createdAt: new Date(),
    };

    if (payload.productCode) {
      orgData.marketPlace = { productCode: payload.productCode };
    }

    // create org document
    await firestore
      .collection('Organizations')
      .doc(id)
      .set(orgData);

    // create access control for owner
    await firestore
      .collection('Organizations')
      .doc(id)
      .collection('accessControl')
      .doc(user.uid)
      .set({
        createdAt: new Date(),
        role: 'Owner',
        isActive: true,
      });

    // add org to user profile
    await updateUserProfile({
      organisations: firebase.firestore.FieldValue.arrayUnion(id),
    });
    return id;
  } catch (err) {
    errorHandler({
      status: err.code,
      statusText: err.message,
      statusRaw: err,
    });
    return false;
  }
};

export async function fetchOrganisation(id) {
  // fetchOrganisations();
  let ref = firestore.collection('Organizations').doc(id);

  return eventChannel(emitter => {
    const unsubscribe = ref.onSnapshot(
      async querySnapshot => {
        let singleDoc = {};

        if (querySnapshot.exists) {
          singleDoc = {
            id: querySnapshot.id,
            ...querySnapshot.data(),
          };
        }

        emitter(singleDoc);
        singleDoc = {};
      },
      err => {
        errorHandler({
          status: err.code,
          statusText: err.message,
          statusRaw: err,
        });
        emitter([]);
      }
    );
    // The subscriber must return an unsubscribe function
    return () => unsubscribe();
  });
}

export async function fetchOrganisationDetails(id) {
  try {
    let querySnapshot = await firestore
      .collection('Organizations')
      .doc(id)
      .get();
    let singleDoc = {};
    if (querySnapshot.exists) {
      singleDoc = {
        id: querySnapshot.id,
        ...querySnapshot.data(),
      };
    }
    return singleDoc;
  } catch (err) {
    errorHandler({
      status: err.code,
      statusText: err.message,
      statusRaw: err,
    });
  }
}

export async function fetchOrganisationsList() {
  const user = currentUser();
  if (!user) return false;

  if (getAuthority().includes('superAdmin')) {
    return list({ module: 'organisation' });
  }

  let ref = firestore.collection('Users').doc(user.uid);

  return eventChannel(emitter => {
    const unsubscribe = ref.onSnapshot(
      async querySnapshot => {
        let docs = [];
        if (querySnapshot.exists) {
          const organisationsData = querySnapshot.data().organisations;

          if (organisationsData && organisationsData.length > 0) {
            await Promise.all(
              organisationsData.map(async item => {
                const itemData = await fetchOrganisationDetails(item);

                docs.push(itemData);
              })
            );
          }
        }

        emitter(docs);
        docs = [];
      },
      err => {
        errorHandler({
          status: err.code,
          statusText: err.message,
          statusRaw: err,
        });
        emitter([]);
      }
    );
    // The subscriber must return an unsubscribe function
    return () => unsubscribe();
  });
}

export async function userExists(email) {
  try {
    const user = await firebase.auth().getUserByEmail(email);
    const isUserExists = user.length > 0;

    if (isUserExists) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error(error);
  }
  return false;
}

export async function forceChangePasswords(payload) {
  const { newPass, oldPass } = payload;

  try {
    let user = await firebase.auth().currentUser;

    let credentials = await firebase.auth.EmailAuthProvider.credential(user.email, oldPass);

    await user.reauthenticateWithCredential(credentials);

    await user.updatePassword(newPass);

    return true;
  } catch (error) {
    message.error('Invalid credentials. Please try again.');
  }
  return false;
}
